import Breadcrumb from "../../components/Breadcrumbs/Breadcrumb"
import DefaultLayout from "../../layout/DefaultLayout"
import Table from "../../components/Table/Table";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getTargetLanguages, deleteTargetLanguageById } from "../../redux/feature/targetLanguage/targetLanguage.service";

import { openModal } from "../../redux/feature/modal/modal.slice";
import CreateLanguage from "./CreateLanguage";
import UpdateLanguage from "./UpdateLanguage";
import GlobalModal from "../../components/GlobalModal/GlobalModal";
import Swal from "sweetalert2";

const LanguageList = () => {

  const dispatch = useDispatch();

  const columns = [
    { title: "Language Name", field: "targetLanguages", span: 1 },
    { title: "Price", field: "price", span: 1 },
    { title: "Action", field: "action", span: 1 }
  ];

  const languageData = useSelector((state) => state?.targetLanguage?.targetLanguages?.targetLanguages)
  console.log("targetLanguageData: ", languageData)

  useEffect(() => {
    dispatch(getTargetLanguages());
  }, [dispatch])


  // NOTE: Most Important thing -> First ensure that <GLobalModal/> component must be renderd to show.
  const createTargetLanguage = () => {
    dispatch(openModal({ componentName: CreateLanguage }))
  }

  const updateTargetLanguage = (id) => {
    dispatch(openModal({ componentName: UpdateLanguage, componentProps: id }))
  }

  const handleDeleteLanguageById = (id) => {

    dispatch(deleteTargetLanguageById(id))
      .then((response) => {
        Swal.fire({
          title: "Success",
          text: response?.data?.message,
          icon: "success",
          timer: 2000,
        }).then(() => {

          dispatch(getTargetLanguages()); // Fetch updated data after success
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error?.response?.data?.message,
          icon: "error",
          timer: 2000,
        });
      });
  }

  return (
    <DefaultLayout>
      <Breadcrumb pageName="Language/Price" />

      <div className="flex justify-end">
        <button 
          className="px-4 py-2 text-nbase text-white bg-[#2E8F96] rounded"
          onClick={createTargetLanguage}
          >
          Create
        </button>
      </div>

      <Table
        cssClassName={'mt-8'}
        columns={columns}
        data={languageData}
        updateTargetLanguage={updateTargetLanguage}
        handleDeleteLanguageById={handleDeleteLanguageById}
      />

      <GlobalModal/>
      
    </DefaultLayout>
  )
}

export default LanguageList