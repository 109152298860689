import Swal from "sweetalert2";
import { getOrders, updateOrder } from "../../redux/feature/order/order.service";
import { useDispatch } from "react-redux";
import { getUsers } from "../../redux/feature/user/user.service";

const SelectMenu = ({ optionValues, item, currentPage, itemsPerPage }) => {
  
  const dispatch = useDispatch();

  const handleStatus = async (STATUS) => {
    try {
      //making an API call.
      await dispatch(updateOrder({ id: item?._id, status: STATUS }))
      .unwrap()
      .then(() => {
        Swal.fire({
          title: "Order Updated!",
          text: "Order has been successfully updated.",
          icon: "success",
          timer:"2000",
          confirmButtonText: "OK",
        }).then((swalResult) => {
          if (swalResult.isConfirmed || swalResult.dismiss === Swal.DismissReason.timer) {
            dispatch(getOrders({ page: currentPage, limit: itemsPerPage }));
          }
        });
      
      })
      
    } catch (error) {
      console.log("Error while updating user", error);
    }
    
  };

  return (
    <div>
      {/* <label htmlFor="status" className="block text-sm font-medium leading-6 text-gray-900">
        Status
      </label> */}
      <select
        id="status"
        name="status"
        className={`mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 cursor-pointer
          ring-1 ring-inset ring-gray-300 focus:outline-none sm:text-sm sm:leading-6`}
        onChange={(e) => handleStatus(e.target.value)}
        value={item?.status} // Set the value here
      >
        {optionValues.map((value, i) => (
          <option key={i} value={value}>
            {value}
          </option>
        ))}
      </select>
    </div>
  );
};

export default SelectMenu;
