import { createSlice } from "@reduxjs/toolkit";
import { getOrders, updateOrder, uploadResendDoc } from "./order.service";

const initialState = {
  isLoading: false,
  orders: [],
  error: null
}

const orderSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
    .addCase(getOrders.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(getOrders.fulfilled, (state, action) => {
      state.isLoading = false;
      state.orders = action.payload;
    })
    .addCase(getOrders.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })

    //updateOrder
    .addCase(updateOrder.pending, (state) => {
      // state.isLoading = true;
    })
    .addCase(updateOrder.fulfilled, (state, action) => {
      // state.isLoading = false;
    })
    .addCase(updateOrder.rejected, (state, action) => {
      // state.isLoading = false;
      state.error = action.payload;
    })

    //uploadDocument
    .addCase(uploadResendDoc.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(uploadResendDoc.fulfilled, (state, action) => {
      state.isLoading = false;
    })
    .addCase(uploadResendDoc.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })
  }

})

export default orderSlice.reducer;