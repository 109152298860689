import { createSlice } from "@reduxjs/toolkit";
import { getTargetLanguages, createTargetLanguage, deleteTargetLanguageById, 
  updateTargetLanguage
 } from "./targetLanguage.service";

const initialState = {
  isLoading: false,
  targetLanguages: [],
  error: null,
}

const targetLanguageSlice = createSlice({
  name: "targetLanguages",
  initialState,
  extraReducers: (builder) => {
    builder
     .addCase(getTargetLanguages.pending, (state) => {
        state.isLoading = true;
      })
     .addCase(getTargetLanguages.fulfilled, (state, action) => {
        state.isLoading = false;
        state.targetLanguages = action.payload;
      })
     .addCase(getTargetLanguages.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      //create targetlanguages
      .addCase(createTargetLanguage.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createTargetLanguage.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(createTargetLanguage.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      //update targetLanguage
      .addCase(updateTargetLanguage.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateTargetLanguage.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(updateTargetLanguage.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      //delete targetLanguage
      .addCase(deleteTargetLanguageById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteTargetLanguageById.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(deleteTargetLanguageById.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
  }
})

export default targetLanguageSlice.reducer;