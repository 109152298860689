import api from '../../../utils/Api';
import { config } from "../../../utils/EndPoints";
import { createAsyncThunk } from "@reduxjs/toolkit";


const createTargetLanguage = createAsyncThunk('targetLanguage/createTargetLanguage', async (data) => {
  try {
    const response = await api.post(config.endPoints.createTargetLanguage, data);
    return response?.data;

  } catch (error) {
      console.log("Error while creating target language: ", error);
  }
})

const getTargetLanguages = createAsyncThunk('targetLanguage/getTargetLanguages', async () => {
  try {
    const response = await api.get(config.endPoints.getTargetLanguages);
    return response?.data;

  } catch (error) {
      console.log("Error while getting targetLanguages: ", error);
  }
})

const updateTargetLanguage = createAsyncThunk('targetLanguage/updateTargetLanguage', async ({ id, data }) => {
  try {
    const response = await api.patch(`${config.endPoints.updateTargetLanguage}/${id}`, data);
    return response?.data;

  } catch (error) {
      console.log("Error while updating target language: ", error);
  }

})

const deleteTargetLanguageById = createAsyncThunk('targetLanguage/deleteTargetLanguageById', async (id) => {
  try {
    const response = await api.delete(`${config.endPoints.deleteTargetLanguageById}/${id}`);
    return response?.data;
    
  } catch (error) {
      console.log("Error while deleting target language: ", error);
  }
})

export { getTargetLanguages, createTargetLanguage, deleteTargetLanguageById, updateTargetLanguage };