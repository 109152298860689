
import api from "../../../utils/Api"
import { config } from "../../../utils/EndPoints"
import { createAsyncThunk } from "@reduxjs/toolkit";

const getUsers = createAsyncThunk("user/getUsers", async ({ page, limit, search = "" }, { rejectWithValue }) => {
  try {
    const response = await api.get(`${config.endPoints.getUsers}?page=${page}&limit=${limit}&search=${search}`)
    return response.data;

  } catch (error) {
     return rejectWithValue(error.message);
  }
});

const getSingleUser = createAsyncThunk(
  'user/getSingleUser',
  async (id, { rejectWithValue }) => {
    try {
      const response = await api.get(`${config.endPoints.getUsers}?id=${id}`);
      return response?.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
 

export { getUsers, getSingleUser };