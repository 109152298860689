const config = {
  BASE_URL: process.env.REACT_APP_BASE_URL,
  
  endPoints: {
    adminRegister: 'admin/register',
    adminLogin: 'admin/login',
    adminLogout: 'admin/logOut',
    getUsers: 'admin/getUsers',
    getCountries: 'countries/getCountries',
    getLanguages: 'languages/getLanguages',
    createOrder: 'order/createOrder',
    getOrders: 'order/getOrders',
    getSingleOrder: 'order/getSingleOrder',
    updateOrder: 'order/updateOrder',
    deleteSingleOrder: 'order/deleteSingleOrder',
    deleteOrders: 'order/deleteOrders',
    myOrder: 'order/myOrder',
    createPayment: 'payment/createPayment',
    createPlan: 'plan/createPlan',
    getPlans: 'plan/getPlans',
    getSinglePlan: 'plan/getSinglePlan',
    updatePlan: 'plan/updatePlan',
    deleteSinglePlan: 'plan/deleteSinglePlan',
    deletePlans: 'plan/deletePlans',
    createService: 'service/createService',
    getServices: 'service/getServices',
    getSingleService: 'service/getSingleService',
    updateService: 'service/updateService',
    deleteSingleService: 'service/deleteSingleService',
    deleteServices: 'service/deleteServices',
    createTargetLanguage: 'targetLanguage/create',
    getTargetLanguages: 'targetLanguage/get',
    getTargetLanguageById: 'targetLanguage/get',
    updateTargetLanguage: 'targetLanguage/update',
    deleteTargetLanguageById: 'targetLanguage/delete',
    deleteAllTargetLanguages: 'targetLanguage/delete',
    createTopic: 'topic/createTopic',
    getTopics: 'topic/getTopics',
    getSingleTopic: 'topic/getSingleTopic',
    updateTopic: 'topic/updateTopic',
    deleteSingleTopic: 'topic/deleteSingleTopic',
    deleteTopics: 'topic/deleteTopics',

    uploadResendDoc: '/upload/resendDoc',
  },
};

export { config };