import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SearchBar from "../../components/SearchBar/SearchBar";
import Pagination from "../../components/Pagination/Pagination";
import Table from "../../components/Table/Table";
import Spinner from "../../components/Spinner/Spinner";

import DefaultLayout from "../../layout/DefaultLayout";
import Breadcrumb from "../../components/Breadcrumbs/Breadcrumb";

import { getUsers } from "../../redux/feature/user/user.service";

const UserList = () => {

  const dispatch = useDispatch();

  const isLoading = useSelector((state) => state?.loading[getUsers.typePrefix]);

  const userData = useSelector((state) => state?.user?.users?.users);
  const totalPages = useSelector((state) => state?.user?.users?.pages);
  const totalRecords = useSelector((state) => state?.user?.users?.total);

  console.log('userData: ', userData)
  console.log('totalPages: ', totalPages)
  console.log('totalRecords: ', totalRecords)

  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const columns = [
    { title: "Full Name", field: "name", span: 1 },
    { title: "Email", field: "email", span: 1 },
    { title: "Phone Number", field: "phone", span: 1 },
    { title: "Role", field: "role", span: 1 },
  ];

  useEffect(() => {
    if (searchQuery !== undefined && searchQuery.trim() !== "") {
      const handler = setTimeout(() => {
        console.log("Dispatching with searchQuery:", searchQuery);
        dispatch(
          getUsers({ page: currentPage, limit: itemsPerPage, search: searchQuery })
        );

      }, 1000);

      return () => {
        clearTimeout(handler);
      };

    } else {
      console.log("Fetching users without searchQuery");
      dispatch(getUsers({ page: currentPage, limit: itemsPerPage }));
    }

  }, [dispatch, currentPage, itemsPerPage, searchQuery]);

  return (
    <DefaultLayout>
      <Breadcrumb pageName="User List" />
      <div className="max-w-full overflow-auto bg-white p-5">
        <div>
          <div className="py-4 gap-3 flex flex-col sm:flex-row justify-between items-center space-x-4">
            <SearchBar
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              itemsPerPage={itemsPerPage}
              setItemsPerPage={setItemsPerPage}
              setCurrentPage={setCurrentPage}
            />
            
          </div>
          {isLoading ? (
            <Spinner />
          ) : totalRecords > 0 ? (
            <div className="min-h-[300px] md:min-h-[600px] bg-white overflow-x-auto">
              <Table
                columns={columns}
                data={userData}
                currentPage={currentPage}
                itemsPerPage={itemsPerPage}
              />
            </div>
          ) : (
            <div className="bg-white flex justify-center items-center min-h-[300px] md:min-h-[600px]">
              <p className="font-bold text-center"> No Record found </p>
            </div>
          )}
        </div>
      </div>
      {totalPages > 1 && (
        <div className="p-4 sm:p-6 xl:p-7.5 flex justify-end">
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={setCurrentPage}
          />
        </div>
      )}
    </DefaultLayout>
  );
};

export default UserList;
